/* eslint-disable no-undef */
import useHttpApiCall from '../../../services/useHttpApiCall';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import {
  apiCallOnce,
  customGraphEnabled,
  sensorClassification,
  variablePricingEnabled,
  singleEquipmentCustomer,
  externalAccessEnabled,
  machineStatusEnabled,
  incomersEnabled,
  generatorsEnabled
} from '../../../redux/company/company.action';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { setUserAccessibility } from '../../../redux/useraccessibility/useraccessibility.action';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import addClarityScript from '../../../analytics/clarity';
//const { NODE_ENV } = process.env;

export default function MainWrapperComponent(props) {
  const [payload, setPayload] = useState(null);
  const [data] = useHttpApiCall(payload);
  const [payload2, setPayload2] = useState(null);
  const [data2] = useHttpApiCall(payload2);
  // const set_user_accessibility = useSelector(
  //   (state) => state.userAccessibility.set_user_accessibility
  // );
  const apiCallOnceRedux = useSelector((state) => state.company.api_call_once);
  const [isOperator] = useState(localStorage.getItem('is_operator'));
  const { trackPageView } = useMatomo();
  const dispatch = useDispatch();
  // const [callAPI, setCallAPI] = useState({ data1: false, data2: false });
  const location = useLocation();

  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line no-undef
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_KEY) {
      ReactGA.send({ hitType: 'pageview', page: location });
    }
  }, [location]);

  useEffect(() => {
    if (!apiCallOnceRedux.company) {
      setPayload({
        auth: true,
        url: 'v1/company/'
      });
    }
  }, []);

  useEffect(() => {
    if (data && data.data) {
      let apiData = data.data.data;
      dispatch(apiCallOnce({ ...apiCallOnceRedux, company: true }));
      dispatch(sensorClassification(apiData.is_temp_humidity_classification_enabled));
      dispatch(customGraphEnabled(apiData.is_custom_graph_enabled));
      dispatch(variablePricingEnabled(apiData.is_variable_pricing_enabled));
      dispatch(singleEquipmentCustomer(apiData.is_single_equipment_customer));
      dispatch(externalAccessEnabled(data.data.data.is_external_access_enabled));
      dispatch(machineStatusEnabled(apiData.is_machine_status_enabled));
      dispatch(incomersEnabled(apiData.is_incomers_classification_enabled));
      dispatch(generatorsEnabled(apiData.is_generators_classification_enabled));

      if (
        process.env.REACT_APP_GOOGLE_ANALYTICS_KEY &&
        !apiData.is_demo_account &&
        apiData.enable_alarm_notification
      ) {
        console.log('enter google analytics');
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);
      }

      if (
        process.env.REACT_APP_CLARITY_ANALYTICS_KEY &&
        !apiData.is_demo_account &&
        apiData.enable_alarm_notification
      ) {
        addClarityScript(process.env.REACT_APP_CLARITY_ANALYTICS_KEY);
      }

      if (
        apiData.is_demo_account != true &&
        apiData.is_operator == true &&
        apiData.enable_alarm_notification
      ) {
        document.title = `DAZOQ - ${apiData.name} - OPERATOR`;
      } else {
        document.title = `DAZOQ - ${apiData.name}`;
      }
      // trackPageView()
    }
  }, [data]);

  useEffect(() => {
    if (!apiCallOnceRedux.accessibilitySettings && isOperator == 'true') {
      setPayload2({
        auth: true,
        url: 'v0/users/accessibility-settings/'
      });
    }
  }, [apiCallOnceRedux]);

  // useEffect(() => {
  //   console.log("callAPI",callAPI.data2)

  // }, [callAPI.data2])

  useEffect(() => {
    if (data2 && data2.data) {
      dispatch(apiCallOnce({ ...apiCallOnceRedux, accessibilitySettings: true }));
      if (data2.data.status == 1) {
        let responseData = data2.data.data;
        localStorage.setItem(
          'chart_font_weight',
          responseData.font_weight == 'regular' ? 'normal' : responseData.font_weight
        );
        localStorage.setItem('chart_font_size_percentage', responseData.text_size_percentage);

        dispatch(
          setUserAccessibility({
            text_size_percentage: responseData.text_size_percentage,
            font_weight:
              responseData.font_weight == 'regular' ? 'normal' : responseData.font_weight,
            id: responseData.id
          })
        );
      }
      // else{
      //   dispatch(setUserAccessibility(""))
      // }
    }
  }, [data2]);

  // useEffect(() => {
  //  console.log("sset_user_accessibility",set_user_accessibility)
  // }, [set_user_accessibility])

  return <>{props.children}</>;
}

import { useState, useEffect, useContext } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import useHttpApiCall from '../../../services/useHttpApiCall';
import { context as ProcessContext } from '../../../context/process.context';

export default function ProcessMenuComponent() {
  const [payload, setPayload] = useState(undefined);
  let [searchParams] = useSearchParams();
  const kpi_view = searchParams.get('kpi');
  const energy_view = searchParams.get('energy');

  useEffect(() => {
    setPayload({
      auth: true,
      url: 'v0/process/'
    });
  }, []);

  const [data] = useHttpApiCall(payload);
  let fetchSidebarLinks = useContext(ProcessContext).fetchSidebarLinks;
  let processmenu = useContext(ProcessContext).state.sidebarLinks;

  useEffect(() => {
    if (data && data.data) {
      fetchSidebarLinks(data.data.data);
    }
  }, [data]);

  const toggleActiveClass = (e) => {
    if (e.target.classList.contains('active')) {
      e.target.classList.remove('active');
    } else {
      e.target.classList.add('active');
    }
  };

  if (processmenu.length) {
    return (
      <div className="daz-sidebar__hoverinfo shadow daz-sidebar__processListing">
        {processmenu.map((unitMenu, i) => {
          return (
            <div key={`${unitMenu.name}_${i}`} className="daz-sidebar__dropdown-wrp">
              <div
                onClick={toggleActiveClass}
                className="daz-sidebar__hoverinfo--item   daz-sidebar__dropdown-hov ">
                {/* <i class="material-icons notranslate graphoptionicons red-color">notifications</i> */}
                <NavLink
                  className={({ isActive }) => (isActive ? ' daz-sidebar__hoverinfo--active' : '')}
                  to={`/processes/${encodeURIComponent(unitMenu.name)}?process_type=${
                    unitMenu.process_type
                  }&process_id=${unitMenu.process_id}&sub_process_id=${
                    unitMenu.sub_process_id
                  }&kpi=${kpi_view}&energy=${energy_view}`}>
                  {' '}
                  {unitMenu.name}
                </NavLink>
              </div>

              {unitMenu.equipments ? (
                <div className="daz-sidebar__dropdown shadow">
                  {unitMenu.equipments.map((machinesMenu) => {
                    return (
                      <div key={machinesMenu.equipment_id} className="daz-sidebar__dropdown--item">
                        {/* <i class="material-icons notranslate graphoptionicons red-color">notifications</i> */}
                        <NavLink
                          className={({ isActive }) =>
                            'daz-sidebar__hoverinfo--item' +
                            (isActive && ' daz-sidebar__hoverinfo--active')
                          }
                          to={`/processes/${encodeURIComponent(unitMenu.name)}/${encodeURIComponent(
                            machinesMenu.equipment_name
                          )}?process_id=${unitMenu.process_id}&sub_process_id=${
                            unitMenu.sub_process_id
                          }&equipment_id=${
                            machinesMenu.equipment_id
                          }&kpi=${kpi_view}&energy=${energy_view}`}>
                          {' '}
                          {machinesMenu.equipment_name}
                        </NavLink>
                      </div>
                    );
                  })}
                </div>
              ) : unitMenu.ugn_zones ? (
                <div className="daz-sidebar__dropdown shadow">
                  {Object.keys(unitMenu.ugn_zones).map((eachZone, i) => {
                    return (
                      <div key={i} className="daz-sidebar__dropdown--item p-0">
                        <div
                          onClick={toggleActiveClass}
                          className={'daz-sidebar__dropdown-wrp w-100'}>
                          <div className="daz-sidebar__hoverinfo--item   daz-sidebar__dropdown-hov ">
                            {eachZone}
                          </div>
                          {unitMenu.ugn_zones[eachZone].equipments ? (
                            <div className="daz-sidebar__dropdown shadow">
                              {unitMenu.ugn_zones[eachZone].equipments.map((machinesMenu) => {
                                return (
                                  <div
                                    key={machinesMenu.equipment_id}
                                    className="daz-sidebar__dropdown--item">
                                    <NavLink
                                      className={({ isActive }) =>
                                        'daz-sidebar__hoverinfo--item' + isActive &&
                                        'daz-sidebar__hoverinfo--active'
                                      }
                                      to={`/processes/${encodeURIComponent(
                                        unitMenu.name
                                      )}/${encodeURIComponent(
                                        machinesMenu.equipment_name
                                      )}?process_type=${unitMenu.process_type}&process_id=${
                                        unitMenu.process_id
                                      }&sub_process_id=${unitMenu.sub_process_id}&equipment_id=${
                                        machinesMenu.equipment_id
                                      }&kpi=${kpi_view}&energy=${energy_view}`}>
                                      {' '}
                                      {machinesMenu.equipment_name}
                                    </NavLink>
                                  </div>
                                );
                              })}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
}

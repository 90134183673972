import axios from 'axios';
import { useEffect, useState } from 'react';
import httpService from './http.service';
import baseUrlConfig from '../config/baseUrl';
function useHttpApiCall(payload) {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    const callApi = async (cancelToken) => {
      try {
        setLoading(true);
        const data = await httpService.fetch(payload, cancelToken);
        setData(data);
        setLoading(false);
        setError(undefined);
      } catch (error) {
        console.log('error show', error);
        if (error && error.status == 401) {
          console.log('refresh error');
          const originalRequest = { ...error.config };
          setData(undefined);
          setLoading(true);

          console.log('refresh token error', error.config);
          const res = await httpService
            .fetch(
              {
                method: 'POST',
                url: `${baseUrlConfig.baseUrl}v0/api/token/refresh/`,
                data: {
                  refresh: localStorage.getItem('refresh_token')
                }
              },
              cancelToken
            )
            .catch(function (error) {
              console.log('Show error notification!', error);
              localStorage.removeItem('token');
              localStorage.removeItem('refresh_token');
              window.location.reload();
            });
          localStorage.setItem('token', res.data.access);
          originalRequest.headers.Authorization = 'Bearer ' + localStorage.getItem('token');

          const data = await httpService.fetch(originalRequest, originalRequest.cancelToken);
          setError(undefined);
          setData(data);
          setLoading(false);
        } else if (error && error.status == 417) {
          localStorage.removeItem('token');
          localStorage.removeItem('refresh_token');
          window.location.reload();
        } else {
          setError(error);
          setData(undefined);
          setLoading(false);
        }
      }
    };

    let isComponentMounted = true;
    const source = axios.CancelToken.source();
    if (isComponentMounted && payload) callApi(source.token);
    return () => {
      isComponentMounted = false;
      source.cancel('Cancelling Request');
    };
  }, [payload]);

  return [data, loading, error];
}

export default useHttpApiCall;
